import "./src/styles/global.css"

// Helper function to check if we're in a browser environment
const isBrowser = () => typeof window !== 'undefined'

// Use onClientEntry to ensure styles are applied early
export const onClientEntry = () => {
  if (!isBrowser()) return
  
  // Apply a basic style to ensure content is visible with system fonts
  // before custom fonts load
  if (document.head) {
    const style = document.createElement('style')
    style.id = 'font-fallback-styles'
    style.textContent = `
      /* Base styles for all devices */
      body, p, h1, h2, h3, h4, h5, h6, button, input, select, textarea {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
      }
      
      /* Force mobile browsers to use fonts correctly */
      @media (max-width: 768px) {
        .font-ubuntu, body, p, h1, h2, h3, h4, h5, h6 {
          font-family: 'Ubuntu', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif !important;
        }
        .font-montserrat {
          font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif !important;
        }
      }
    `
    document.head.appendChild(style)
    
    // Add links to directly load web fonts without delay
    const fontLinks = document.createElement('div')
    fontLinks.innerHTML = `
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link href="https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap" rel="stylesheet">
      <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap" rel="stylesheet">
    `
    document.head.appendChild(fontLinks)
  }
}

// Use onInitialClientRender for actual font loading after DOM is ready
export const onInitialClientRender = () => {
  if (!isBrowser()) return
  
  // Check if user agent is mobile
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
  
  // Immediately mark document as loading fonts
  document.documentElement.classList.add('fonts-loading')
  
  // Apply special class for mobile
  if (isMobile) {
    document.documentElement.classList.add('mobile-device')
    
    // Force mobile browsers to use the fonts
    const forceFonts = () => {
      // Apply font directly to body and all text elements
      document.body.style.fontFamily = "'Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif"
      
      // Select all text elements and apply fonts directly
      const textElements = document.querySelectorAll('p, h1, h2, h3, h4, h5, h6, button, a, span')
      textElements.forEach(el => {
        const element = el;
        if (!element.classList.contains('font-montserrat')) {
          element.style.fontFamily = "'Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif"
        } else {
          element.style.fontFamily = "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif"
        }
      })
    }
    
    // Apply fonts immediately and after a short delay
    forceFonts()
    setTimeout(forceFonts, 500)
  }
  
  // Actively use the preloaded fonts immediately after load to avoid warnings
  window.addEventListener('load', () => {
    // Remove the fallback style after fonts are loaded
    const fallbackStyle = document.getElementById('font-fallback-styles')
    if (fallbackStyle) {
      fallbackStyle.remove()
    }
    
    // Force font usage to ensure preloaded fonts are actually used
    const forceUsage = document.createElement('div')
    forceUsage.style.position = 'absolute'
    forceUsage.style.opacity = '0'
    forceUsage.style.fontFamily = "'Ubuntu', 'Montserrat'"
    forceUsage.style.visibility = 'hidden'
    forceUsage.textContent = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    document.body.appendChild(forceUsage)
    
    // Apply classes
    document.documentElement.classList.add('fonts-ready')
    document.documentElement.classList.add('fonts-loaded')
    document.documentElement.classList.remove('fonts-loading')
    
    if (isMobile) {
      document.documentElement.classList.add('fonts-force-mobile')
    }
    
    // Apply fonts directly for mobile
    if (isMobile) {
      document.body.style.fontFamily = "'Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif"
    }
    
    // Remove the temporary element after a short delay
    setTimeout(() => {
      forceUsage.remove()
    }, 100)
  })
  
  // Set a fallback to ensure content is visible even if fonts fail
  setTimeout(() => {
    document.documentElement.classList.add('fonts-ready')
    document.documentElement.classList.add('fonts-loaded')
    document.documentElement.classList.remove('fonts-loading')
    
    if (isMobile) {
      document.documentElement.classList.add('fonts-force-mobile')
    }
  }, 1000)
}
